<ng-container>
  <c-container [fluid]="true" class="border-bottom px-4">
    <button
      [cSidebarToggle]="sidebarId"
      cHeaderToggler
      class="btn"
      toggle="visible"
      style="margin-inline-start: -14px"
    >
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button>

    <c-header-nav class="d-none d-md-flex ms-auto"> </c-header-nav>

    <c-header-nav class="ms-auto ms-md-0">
      <ng-container *ngTemplateOutlet="themeDropdown"></ng-container>
      <div class="nav-item py-1">
        <div class="vr h-100 mx-2 text-body text-opacity-75"></div>
      </div>
    </c-header-nav>

    <c-header-nav class="ms-0">
      <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
    </c-header-nav>
  </c-container>
</ng-container>

<ng-template #userDropdown>
  <c-dropdown
    [popperOptions]="{ placement: 'bottom-start' }"
    variant="nav-item"
  >
    <button [caret]="false" cDropdownToggle class="py-0">
      <div
        class="rounded-circle d-flex align-items-center justify-content-center"
        [style.width.px]="40"
        [style.height.px]="40"
        [style.backgroundColor]="'#6F89A5'"
        [style.color]="'#fff'"
        [style.fontSize.px]="18"
      >
        {{ getUserInitials() }}
      </div>
    </button>
    <ul cDropdownMenu class="pt-0 w-auto">
      <li>
        <h6
          cDropdownHeader
          class="bg-body-secondary text-body-secondary fw-semibold py-2 rounded-top"
        >
          Account
        </h6>
      </li>
      <li>
        <a cDropdownItem routerLink="/profile">
          <svg cIcon class="me-2" name="cilUser"></svg>
          Profile
        </a>
      </li>
      <li>
        <hr cDropdownDivider />
      </li>
      <li>
        <a cDropdownItem routerLink="/logout">
          <svg cIcon class="me-2" name="cilAccountLogout"></svg>
          Logout
        </a>
      </li>
    </ul>
  </c-dropdown>
</ng-template>

<ng-template #themeDropdown>
  <c-dropdown alignment="end" variant="nav-item">
    <button [caret]="false" cDropdownToggle>
      @switch (colorMode()) { @case ("dark") {
      <svg cIcon name="cilMoon" size="lg"></svg>
      } @case ("auto") {
      <svg cIcon name="cilContrast" size="lg"></svg>
      } @default {
      <svg cIcon name="cilSun" size="lg"></svg>
      } }
    </button>
    <div cDropdownMenu>
      <button
        (click)="colorMode.set('light')"
        [active]="colorMode() === 'light'"
        [routerLink]="[]"
        cDropdownItem
        class="d-flex align-items-center"
      >
        <svg cIcon class="me-2" name="cilSun" size="lg"></svg>
        Light
      </button>
      <button
        (click)="colorMode.set('dark')"
        [active]="colorMode() === 'dark'"
        [routerLink]="[]"
        cDropdownItem
        class="d-flex align-items-center"
      >
        <svg cIcon class="me-2" name="cilMoon" size="lg"></svg>
        Dark
      </button>
      <button
        (click)="colorMode.set('auto')"
        [active]="colorMode() === 'auto'"
        [routerLink]="[]"
        cDropdownItem
        class="d-flex align-items-center"
      >
        <svg cIcon class="me-2" name="cilContrast" size="lg"></svg>
        Auto
      </button>
    </div>
  </c-dropdown>
</ng-template>
