import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet,NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from './icons/icon-subset';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  template: '<router-outlet />',
  standalone: true,
  imports: [RouterOutlet, HttpClientModule],
})
export class AppComponent implements OnInit {
  title = 'HalcyonSign';
  private isProfileLoaded = false; // Flag to control profile setup

  constructor(
    private router: Router,
    private titleService: Title,
    private iconSetService: IconSetService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.titleService.setTitle(this.title);
    this.iconSetService.icons = { ...iconSubset };
  }

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        )
      )
      .subscribe((result: EventMessage) => {
        if (!this.isProfileLoaded) {
          const authResult = result.payload as AuthenticationResult;
          if (authResult.account) {
            const idTokenClaims = authResult.account.idTokenClaims;
            const name = idTokenClaims?.name || 'Unknown';
            const oid = idTokenClaims?.oid || 'Unknown';
            const email = idTokenClaims?.preferred_username || 'Unknown';
            const initials = this.getInitials(name);
            const profile = { name, email, oid, initials };
            sessionStorage.setItem('profile', JSON.stringify(profile));
            this.isProfileLoaded = true;
          }
        }
      });

    this.router.events
      .pipe(filter(evt => evt instanceof NavigationEnd))
      .subscribe();
  }

  getInitials(fullName: string): string {
    const [firstName, lastName = ''] = fullName.split(' ');
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  }
}
