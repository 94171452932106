import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CardComponent,
  CardBodyComponent,
  CardHeaderComponent,
} from '@coreui/angular-pro';

type ProfileType = {
  name?: string;
  email?: string;
  oid?: string;
};

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  standalone: true,
  imports: [
    CardComponent,
    CardBodyComponent,
    CardHeaderComponent,
    CommonModule
  ]
})
export class ProfileComponent implements OnInit {
  profile: ProfileType = {};

  ngOnInit() {
    this.getProfile();
  }

  getProfile() {
    const profileData = sessionStorage.getItem('profile');
    if (profileData) {
      this.profile = JSON.parse(profileData);
    } else {
      console.error('No profile data found in session storage.');
    }
  }
}
