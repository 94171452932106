<!--<c-footer>-->
  <div>
    <a href="https://halcyonsw.com" target="_blank">halcyonsw.com</a>
    <span> &copy; 2024 HalcyonSign</span>
  </div>
  <div class="ms-auto">
    Powered by
    <a href="https://halcyonsw.com" target="_blank">
      <span>Halcyon</span>
    </a>
  </div>
<!--</c-footer>-->
