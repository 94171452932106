<c-card class="mb-4 shadow-sm">
  <c-card-header>
    <h5 class="mb-0">Profile Information</h5>
  </c-card-header>
  <c-card-body>
    <div class="mb-3">
      <div class="text-muted">
        <strong>Name:</strong>
      </div>
      <div>
        {{ profile?.name || 'N/A' }}
      </div>
    </div>
    
    <div class="mb-3">
      <div class="text-muted">
        <strong>Object ID:</strong>
      </div>
      <div>
        {{ profile?.oid || 'N/A' }}
      </div>
    </div>
    
    <div class="mb-3">
      <div class="text-muted">
        <strong>Email:</strong>
      </div>
      <div>
        {{ profile?.email || 'N/A' }}
      </div>
    </div>
  </c-card-body>
</c-card>
