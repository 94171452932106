import { Routes } from '@angular/router';
import { DefaultLayoutComponent } from './layout';
import { MsalGuard } from '@azure/msal-angular';
import { LogoutComponent } from './logout/logout.component';
import { ProfileComponent } from './profile/profile.component'; 
export const routes: Routes = [
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '',
    redirectTo: 'transactions',
    pathMatch: 'full'
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [MsalGuard],
    children: [
      {
        path: 'transactions',
        loadChildren: () => import('./views/transactions/routes').then((m) => m.routes)
      },
      {
        path: 'archives',
        loadChildren: () =>
          import('./views/archives/routes').then((m) => m.routes)
      },
      {
        path: 'trash',
        loadChildren: () =>
          import('./views/trash/routes').then((m) => m.routes)
      },
      {
        path: 'documents',
        loadChildren: () =>
          import('./views/documents/routes').then((m) => m.routes)
      },
      {
        path: 'contacts',
        loadChildren: () =>
          import('./views/contacts/routes').then((m) => m.routes)
      },
      {
        path: 'profile',
        component: ProfileComponent  
      }
    ]
  },
  { path: '**', redirectTo: 'transactions' }
];